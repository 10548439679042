import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { Credentials, LoginUser } from '../api/login';
//import { PerfilUsuario } from '../api/profile';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Message } from '@axks/components';

@Injectable({
  providedIn: 'root'
})
export class LoginCrestService {

  constructor(private httpClient: HttpClient) {}

  authenticate(loginUser: LoginUser): Observable<Credentials> {
    let path = environment.loginMs;

    const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers',"*")
            /* .set('Access-Control-Allow-Headers','X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method')
            .set("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT, DELETE")
            .set("Allow","GET, POST, OPTIONS, PUT, DELETE") */;

    return this.httpClient.post(path, loginUser, {headers}) as Observable<Credentials>;
  }

  /* getProfile(credentials: Credentials): Observable<PerfilUsuario> {
    let path = environment.perfilMs;

    const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers',"*")
            .set('Authorization',credentials.token);

    return this.httpClient.get(path, {headers}) as Observable<PerfilUsuario>;
  }

  getSingleProfile(cred: string): Observable<ProfileC> {
    let path = environment.sprofile+"/";
    console.log("Singleprofile path: ", path);
    
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*")
      .set('Authorization', cred);
    
    return this.httpClient.get(path, {headers});
  } */

  closeSession(credentials: Credentials): Observable<Message> {
    let path = environment.logoutMs;

    const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers',"*")
            .set('Authorization',credentials.token);

    return this.httpClient.post(path, {}, {headers}) as Observable<Message>;
  }

  finishNotClosedSession(user: string, deviceType: string){
    let path = environment.logoutMs+"/"+user+"/"+deviceType;

    const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers',"*");

    return this.httpClient.get(path, {headers}) as Observable<Message>;
  }
}
