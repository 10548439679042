import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificacionRegistrar } from '../api/notificaciones';
import { Message } from '@axks/components';

@Injectable({
  providedIn: 'root'
})
export class NotifRestcService extends ARestClient {

  constructor(client: HttpClient){
    super(client);
  }

  getBaseEndpoint(): string {
    return environment.notifMs + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  listaNotifsEmpleado(idEmpleado: number): Observable<any>{
    //let path = environment.notificacionRegistrarMs + "/lista-notificaciones/" + idEmpleado;
    let path = environment.notifMs + "/lista-notificaciones/" + idEmpleado;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.client.get(path,{headers});
  }

}

@Injectable({
  providedIn: 'root'
})
export class NotificacionRegistrarService {

constructor(private httpClient: HttpClient) { }

  enviarRegistro(notificacion: NotificacionRegistrar): Observable<NotificacionRegistrar> {
    //let path = environment.notificacionRegistrarMs;
    let path = environment.notifMs;

    const headers = new HttpHeaders()
          .set('Access-Control-Allow-Origin', "*")
          .set('Access-Control-Allow-Headers',"*")
        //.set('Authorization',credentials.token);
    return this.httpClient.post(path, notificacion, {headers}) as Observable<NotificacionRegistrar>;
  }

  notificaVisto(idDest: number, idNotif: number): Observable<Message> {
    let params = {
      "idDestinatario": idDest,
      "idNotificacion": idNotif
    };
    //let path = environment.notificacionRegistrarMs+"/visto-notificacion";
    let path = environment.notifMs+"/visto-notificacion";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, params, { headers }) as Observable<Message>;
  }
}


@Injectable({
  providedIn: 'root'
})
export class NotificacionIndividualService {

  constructor( private http: HttpClient ) { }

  getNotificacionIndividual() {
    return [ 
        {nombre: "Angel", idDestinatario: "0"}, 
        {nombre: "Albania", idDestinatario: "1" }, 
        {nombre: "Berenice", idDestinatario: "2"}, 
        {nombre: "Brenda", idDestinatario: "3"}, 
        {nombre: "Bruno", idDestinatario: "4"}, 
        {nombre: "Camila", idDestinatario: "5"}, 
        {nombre: "Carlos", idDestinatario: "6"}, 
        {nombre: "Citlali", idDestinatario: "7"}, 
        {nombre: "Daniel", idDestinatario: "8"}, 
        {nombre: "David", idDestinatario: "9"}, 
        {nombre: "Eduardo", idDestinatario: "10"}, 
        {nombre: "Esteban", idDestinatario: "11"}, 
        {nombre: "Fabian", idDestinatario: "12"}, 
        {nombre: "Federico", idDestinatario: "13"}, 
        {nombre: "Ignacio", idDestinatario: "14"}, 
        {nombre: "Irma", idDestinatario: "15"}, 
        {nombre: "Juan", idDestinatario: "16"}, 
        {nombre: "Lalo", idDestinatario: "17"}, 
        {nombre: "Zoe", idDestinatario: "18"}
      ]
  }

}
