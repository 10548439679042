import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginCrestService } from '../client/login-crest.service';
import { Credentials, LoginUser } from '../api/login';
import { CookieService } from 'ngx-cookie-service';
import { SessionPortalProv } from '../provs/session-portal-prov.service';
import { Message } from '@axks/components';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  showCloseSession: Subject<void> = new Subject<void>();
  invalidUser: Subject<void> = new Subject<void>();

  constructor(private restc: LoginCrestService,
    protected router: Router,
    private cookieService: CookieService,
    protected sessionProvier: SessionPortalProv,
    protected messageService: MessageService) { }

  //!!!!!! NOTA. VERIFICAR QUE LA COMPAÑIA SIEMPRE ESTE INFORMADA
  authenticate(loginUser: LoginUser) {

    this.restc.authenticate(loginUser)
      .subscribe({
        next: (data) => {
          console.log("Login: ",data);
          
          let cred = data as Credentials;
          let perfilLogin = cred.perfil;
          perfilLogin.perfil['dashPanels'] = perfilLogin.dashPanels;
          
          sessionStorage.setItem("cred", cred.token);
          cred.initDate = new Date();

          this.sessionProvier.token.next(cred);

          this.sessionProvier.profile.next(perfilLogin.perfil);

          let sprofile = perfilLogin.perfilCliente;
          sprofile.isClient = true;
          sprofile.aplicacion = 1;
          sprofile.empresa = this.sessionProvier.empresa.value;
          console.log("Single profile: ", sprofile);

          this.sessionProvier.singleProfile.next(sprofile);
          
        },
        error: (error) => {
          this.invalidUser.next();
          this.sessionProvier.loadingContent.next(false);

          console.log("Error de autenticación")
          console.log(error.error.message);
          let mess = error.error as Message;

          console.log(mess.message);
          if (mess.message.includes('No fue posible localizar el elemento')) {
            mess.message = "Usuario y/o contraseña inválido(s)";
          }



          let mdet = mess.message || environment.messages.error.autentica;
          this.messageService.add({ severity: "error", summary: "Error de autenticación", detail: mdet })
          if (mess.message === 'Sólo es posible tener abierta una sesión por dispositivo.') {
            this.showCloseSession.next();
          }
        }
      });
  }

  haveAnOpenSession() {
    this.sessionProvier.recoverySession();
  }


  dispatch() {
    this.router.navigate(["portal"]);
  }

  closeSession() {

    let iframes = document.getElementsByTagName("iframe");
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        iframes.item(i).contentWindow.postMessage("endsession", "*");
      }
    }

    let cred = this.sessionProvier.token.value;
    this.restc.closeSession(cred).subscribe({
      next: (data) => {
        this.sessionProvier.closeSession.next();
        let mess = data as Message;
        this.messageService.add({ severity: "info", summary: "Portal", detail: mess.message });

        this.invalidateSession();
        this.sessionProvier.profile.next(undefined);
        this.router.navigate([""]);
      },
      error: (error) => {
        console.log(error);
        let mess = error.error as Message;
        this.messageService.add({ severity: "error", summary: "Error de autenticación", detail: mess.message })
        this.invalidateSession();
        this.sessionProvier.profile.next(undefined);
        this.router.navigate([""]);
      }
    })
  }

  redirectToLogin() {
    this.router.navigate([""]);
  }


  invalidateSession() {
    sessionStorage.clear();
    this.sessionProvier.invalidate();
  }

}
