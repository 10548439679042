import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/portal/login/login.component';
import { PortalComponent } from './views/portal/portal/portal.component';
import { BlankComponent } from './views/portal/blank/blank.component';
import { DashboardComponent } from './views/portal/dashboard/dashboard.component';

const routes: Routes = [
  {path:"", component:LoginComponent},
  {path:"portal", component:PortalComponent},
  {path:"blank", component:BlankComponent },
  {path:"home", component:PortalComponent},
  {path:"dashboard", component:DashboardComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
