import { Component, OnInit } from '@angular/core';
import { DeviceView, Message, } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoginCrestService } from 'src/app/client/login-crest.service';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';

@Component({
  selector: 'axks-close-session',
  templateUrl: './close-session.component.html',
  styles: [
  ]
})
export class CloseSessionComponent extends DeviceView implements OnInit {

  user?: string;
  constructor(protected deviceService: DeviceDetectorService,
    protected messageService:MessageService,
    protected sessionProvier: SessionPortalProv,
    private logoutRestc: LoginCrestService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig) {
    super(deviceService, messageService);
  }

  ngOnInit(): void {
    this.user = this.config.data['user'];
  }

  cerrar(event){
      let device = this.device.type as string;
      
      
      this.logoutRestc.finishNotClosedSession(this.user,device).subscribe({
        next: (data) => {
          let mess = data as Message;
          this.sessionProvier.invalidate();
          this.ref.close();
          this.messageService.add({severity:'success', summary:'Sesión', detail:'La sesión fue cerrada correctamente.'})
          console.log(mess);
        },
        error: (error) => {
          this.messageService.add({severity:'error ', summary:'Sesión', detail:'Ocurrió un error al intentar cerrar la sesión.'})
          console.log(error);
          this.sessionProvier.invalidate();
        }
      });
    
    
  }

}