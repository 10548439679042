import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RebootPassCrestService extends ARestClient {


  getBaseEndpoint(): string {
    return environment.rebootPass + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  constructor(httpClient : HttpClient) {
    super(httpClient);
  }

  rebootPassword(user: String): Observable<Message> {

    let path = this.getBaseEndpoint() + "cambiar-password/" + user;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.client.get(path, {headers}) as Observable<Message>;

  }

}