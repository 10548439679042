import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { DeviceView } from '@axks/components';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'
  ]
})
export class HeaderComponent extends DeviceView implements OnInit, OnDestroy {

  version;
  logoSistema;
  logoMinSistema;
  idEmpresa;
  loadContent = false;

  @Output() dashbRedirect = new EventEmitter<boolean>();

  profileSubs: Subscription;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private cookieService: CookieService,
    protected sessionProvier: SessionPortalProv) {

    super(deviceService, messageService);
    
    this.profileSubs = sessionProvier.profile.subscribe(
      (data) => {
        this.loadContent = true;
      }
    );
  }

  ngOnInit(): void {
    if (this.sessionProvier.profile.value) {
      this.loadContent = true;
    }
    this.version = environment.version;
    this.initCompany();
  }

  ngOnDestroy(): void {
    this.profileSubs.unsubscribe();
  }

  logoClick(event) {
    this.dashbRedirect.emit(true);
  }

  initCompany() {
    let company = this.cookieService.get("e");
    this.idEmpresa = company;
    switch (company) {
      case "3":
        //this.logoSistema = "syrhax/logo-166x30.png";
        //this.logoSistema = "syrsax/LETRAS_70X70PX-sin-fondo1.png";
        this.logoSistema = "syrsax/LETRAS_70X70PX-sin-fondo1.png";
        this.logoMinSistema = "syrsax/CASCO_70X70PX.png"
        break;
      case "1":
        this.logoSistema = "syrsax/LETRAS_70X70PX-sin-fondo1.png";
        this.logoMinSistema = "syrsax/CASCO_70X70PX.png"
        break;
      case "2":
      default:
        this.logoSistema = "syrsax/LETRAS_70X70PX-sin-fondo1.png";
        this.logoMinSistema = "syrsax/CASCO_70X70PX.png"
        break;

    }
  }
}