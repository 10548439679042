import packageInfo from '../../package.json';

const hostMs = "https://cdev.habitatus.axks.ddns.net/ms";
const domPortal = "dev.habitatus.axks.ddns.net";
const hostGui = "https://cdev.habitatus.axks.ddns.net/gui";

export const environment = {
  production: false,
  uat: false,
  dev: true,
  version: packageInfo.version,
  sprofile: hostMs + "/autentica/profile",
  loginMs: hostMs + '/autentica/login',
  logoutMs: hostMs + '/autentica/logout',
  perfilMs: hostMs + '/autentica/profile',
  rebootPass: hostMs + '/autentica/user',
  notifMs: hostMs + '/notificaciones/notificaciones',
  notifRegGui: hostGui + '/gtools/notif',

  messages: {
    error: {
      autentica: "Usuario o contraseña no válida."
    }
  },
  empresas: [
    { id: 1, hostMs: domPortal },
  ],
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }


};

