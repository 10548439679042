import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DeviceView } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DashPanel } from 'src/app/api/profile';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';

@Component({
  selector: 'axks-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends DeviceView implements OnInit, AfterViewInit {

  dashPanels: DashPanel[] = [];

  showSolicitudes: boolean = false;
  showGrafSolMens: boolean = false;

  loadContent: boolean = false;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionPortalProv) {
    super(deviceService, messageService);
  }

  ngOnInit(): void {

      this.getDashboardPanels();
      this.loadContent = true;

  }

  ngAfterViewInit(): void {

  }

  getDashboardPanels() {


    this.dashPanels = [];

    if (!this.sessionProvier.hasRol(1)) {
      //let profile = this.getProfile();
      let profile = this.sessionProvier.profile.value;
      let empresa = sessionStorage.getItem("e") || 1;
      let aplicacion = sessionStorage.getItem("a") || 1;
      let cred = sessionStorage.getItem("cred");

      this.dashPanels = [];//profile.dashPanels;

      let iniTime = 0;
      profile['dashPanels'].forEach(dash => {

        //setTimeout(()=>{
        //dash.url += "?e="+empresa+"&a="+aplicacion+"&s="+cred;
        dash['urlComplete'] = dash.url + "?e=" + empresa + "&a=" + aplicacion + "&s=" + window.btoa(cred);
        this.dashPanels.push(dash);
        //}, iniTime)
        iniTime = iniTime + 1000;
      });
    }

  }


  


}
