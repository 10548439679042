import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { RebootPassCrestService } from '../client/reboot-pass-crest.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RebootPassPsService extends BaseProcess<RebootPassCrestService>{

  client : RebootPassCrestService;

  constructor(httpClient : HttpClient, crest : RebootPassCrestService) {
      super(httpClient, crest);
      this.client = crest;
   }

   changePass(user : string) : Observable<Message>{

    let result = this.client.rebootPassword(user);

    return result;
    
   }


}
