<div class="grid ptl-container" *ngIf="loadContent">
    <div style="height: 2vh;" *ngIf="isMobile()"></div>
    <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 ptl-header">
        <axks-header class="ptl-header" (dashbRedirect)="handleDashboard($event)">
            <axks-menu-bar class="mobile" (onSelMenu)="selMenuHandler($event)"
                *ngIf="isMobile()"></axks-menu-bar>
        </axks-header>
    </div>
    <div class="col-12 pt-content" *ngIf="isMobile()">
        <div class="ptl-ws">
            <p-progressSpinner *ngIf="showProgress" [style]="{width: '50px', height: '50px'}"
                styleClass="axks-progress-spinner" strokeWidth="8"></p-progressSpinner>

            <axks-dashboard *ngIf="showDashboard == true"></axks-dashboard>
            <iframe id="iws" (load)="iframeLoad($event);" #iws width="100%" height="100%" frameBorder="0"
                *ngIf="showDashboard == false"></iframe>
        </div>
    </div>
    <div class="col-12 ptl-content" *ngIf="isDesktop()">

        <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12  ptl-wk-area">
            <div class="grid">
                <div class="col-12 ptl-menu" >
                    <axks-menu-bar [menuItems]="menuItems" (onSelMenu)="selMenuHandler($event)"
                        *ngIf="menuItems"></axks-menu-bar>
                </div>
                <div class="col-12 ptl-ws">
                    <p-progressSpinner *ngIf="showProgress" [style]="{width: '50px', height: '50px'}" styleClass="axks-progress-spinner" strokeWidth="8" ></p-progressSpinner>
                    
                    <axks-dashboard *ngIf="showDashboard == true"></axks-dashboard> 
                    <iframe id="iws" (load)="iframeLoad($event);" #iws width="100%" height="100%" frameBorder="0" *ngIf="showDashboard == false"></iframe>

                    
                </div>

            </div>
        </div>
    </div>


</div>

<p-toast></p-toast>