import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { NotifRestcService, NotificacionRegistrarService } from '../client/notif-restc.service';

@Injectable({
  providedIn: 'root'
})
export class NotifPsService extends BaseProcess<NotifRestcService>{
 
  client: NotifRestcService;

  constructor( http: HttpClient, restc: NotifRestcService) { 
    super(http, restc);
    this.client = restc;
  }

  notificacionesEmpleado(idEmpleado: number): Observable<any>{
    return this.client.listaNotifsEmpleado(idEmpleado);
  }

  detalleNotificacion(idNotif: number): Observable<any>{
    return this.client.findById(idNotif+"?idNotificacion="+idNotif);
  }

}

@Injectable({
  providedIn: 'root'
})
export class NotifRegPsService {

  constructor(private restc: NotificacionRegistrarService) { }


  notificaVisto(idDest: number, idNotif: number): Observable<Message>{
    return this.restc.notificaVisto(idDest, idNotif);
  }
}
