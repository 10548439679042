import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';


import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';

import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

import { AvatarModule } from 'primeng/avatar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToolbarModule } from 'primeng/toolbar';
import { BadgeModule } from 'primeng/badge';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MegaMenuModule } from 'primeng/megamenu';


import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';




import { AppComponent } from './app.component';
import { LoginComponent } from './views/portal/login/login.component';
import { CloseSessionComponent } from './views/portal/close-session/close-session.component';
import { PortalComponent } from './views/portal/portal/portal.component';
import { HeaderComponent } from './views/portal/header/header.component';
import { SystemActionsComponent } from './views/portal/system-actions/system-actions.component';
import { MenuBarComponent } from './views/portal/menu-bar/menu-bar.component';
import { DashboardComponent } from './views/portal/dashboard/dashboard.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ProfileComponent } from './views/portal/profile/profile.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BlankComponent } from './views/portal/blank/blank.component';
import { ForgotPasswordComponent } from './views/portal/forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CloseSessionComponent,
    PortalComponent,
    HeaderComponent,
    SystemActionsComponent,
    MenuBarComponent,
    DashboardComponent,
    SafeUrlPipe,
    ProfileComponent,
    BlankComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    ToastModule,
    MenubarModule,
    OverlayPanelModule,
    TableModule,
    ConfirmDialogModule,
    MessagesModule,
    RadioButtonModule,
    CheckboxModule,
    MultiSelectModule,
    AutoCompleteModule,
    DialogModule,
    DynamicDialogModule,
    AvatarModule,
    TieredMenuModule,
    ToolbarModule,
    BadgeModule,
    SlideMenuModule,
    ProgressSpinnerModule,
    FontAwesomeModule,
    MegaMenuModule
  ],
  providers: [MessageService,
    { provide: 'env', useValue: environment }, CookieService,
    /* {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi:true} */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
