import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, AfterContentChecked, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SessionPortalProv } from './provs/session-portal-prov.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'axks-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {

  readonly HABITAT_US=1;

  title = 'Detonador';
  loadContent = false;
  showProgress = false;

  loadingSubs: Subscription;


  constructor(private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    @Inject(DOCUMENT) private doc: Document,
    private sessPortalProv: SessionPortalProv,
    private cdr: ChangeDetectorRef) {
    this.loadingSubs = sessPortalProv.loadingContent.subscribe({
      next: (data) => {
        this.showProgress = data;
      }
    });

  }

  ngOnInit(): void {
    this.sessPortalProv.recoverySession();
    this.loadContent = true;
    this.loadDefaultTheme();
    this.validateCompany();

    // Default application
    this.sessPortalProv.aplicacion.next(1);

    let cred = this.sessPortalProv.token.value;

    if (cred && cred.token && cred.initDate) {
      if (window.location && window.location.pathname && window.location.pathname !== "/blank")
        this.router.navigate(["portal"]);
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.loadingSubs.unsubscribe();
  }


  validateCompany(): void {
    this.cookieService.set("e", this.HABITAT_US.toString());
    this.cookieService.set("se", this.HABITAT_US.toString());
    this.sessPortalProv.empresa.next(this.HABITAT_US);

    /* this.activatedRoute.queryParams.subscribe((params) => {
      let pcompany;
      try {
        pcompany = params.e || 0;
        pcompany = parseInt(pcompany);
      } catch (e) {
        pcompany = 0;
      }

      if (pcompany > 0) {
        // Se informa la empresa, registra y selecciona empresa
        let compid = pcompany.toString();
        this.cookieService.set("e", compid);
        this.cookieService.set("se", compid);
        this.sessPortalProv.empresa.next(pcompany);
      } else {
        // no se informa la empresa
        // la recupera de las cookies
        let ce = this.cookieService.get("e");
        let cse = this.cookieService.get("se");

        // Localiza la cookie para la compañia
        if (ce) {
          pcompany = parseInt(ce);
          // Localiza la cookie para la compañia seleccionada
          if (cse) {
            pcompany = parseInt(cse);
          } else {
            // No localiza la cookie para la compañia seleccionada
            this.cookieService.set("se", ce);
          }
          this.sessPortalProv.empresa.next(pcompany);
          return;
        } else {
          pcompany = this.findIdFromDomain();
          let compid = pcompany.toString();
          this.cookieService.set("e", compid);
          this.cookieService.set("se", compid);
          this.sessPortalProv.empresa.next(pcompany);
        }
      }
    }); */
  }

  /* findIdFromDomain(): number {
    let clientDom = document.location.hostname;
    let emp = environment.empresas.find((empresa) => empresa.domain == clientDom);

    if (isDevMode())
      console.log("empresa localizada", emp);

    if (!emp) {
      console.debug("No fue localizada la empresa se entrega la empresa 1");
    }

    return emp.id || 1;
  } */

  loadDefaultTheme() {
    let link = this.doc.getElementById("theme");
    if (!link) {
      link = this.doc.createElement("link");
      link.setAttribute("id", "theme");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", environment.theme.default);
      link.addEventListener("load", function () {
        this.loadContent = true;

      }.bind(this));
      this.doc.head.appendChild(link);
    }
    /* setTimeout(()=>{
      this.loadContent=true;
    },1000); */


  }
}
