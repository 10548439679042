<br>
<div class="grid" style="width: 100%;padding-left: 20px;">
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 auth-field-row" >
        <span class="p-float-label" >
            <input id="float-input-user" type="text" pInputText [(ngModel)]="user"> 
            <label for="float-input-user">Username</label>
        </span>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 auth-button-row">
        <p-button label="Cambiar Contraseña" (click)="rebootPass($event)" ></p-button>
    </div>
</div>