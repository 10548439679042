import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { DeviceView } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { LoginUser } from 'src/app/api/login';
import { CookieService } from 'ngx-cookie-service';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';
import { LoginService } from 'src/app/procs/login.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { CloseSessionComponent } from '../close-session/close-session.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'axks-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [DialogService]
})
export class LoginComponent extends DeviceView implements OnInit, OnDestroy {

  auser: LoginUser = {};

  showCloseSession: boolean = false;

  bgLoginImage;
  bgBodyImage;
  logoEmpresa;
  logoEmpresaClass;

  loadLogin: boolean;

  showCloseSessionSubs: Subscription;
  invalidUserSubs: Subscription;
  profileSubs: Subscription;

  //private profileClient: ProfileCrestService,
  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionPortalProv,
    private loginService: LoginService,
    private cookieService: CookieService,
    private dialogService: DialogService) {
    super(deviceService, messageService);
    this.showCloseSessionSubs = loginService.showCloseSession.subscribe({
      next: () => {
        this.showCloseSession = true;
        this.loadLogin = false;
      }
    });

    this.invalidUserSubs = loginService.invalidUser.subscribe({
      next: () => {
        this.loadLogin = false;
      }
    });

    this.profileSubs = this.sessionProvier.profile.subscribe({
      next: (data) => {
        if (data)
          this.loginService.dispatch();
      }
    })

  }

  ngOnInit(): void {
    this.loadLogin = false;
    this.initCompany();
    this.auser = {
    };


    //this.sessionProvier.recoverySession();
    if (isDevMode())
      console.log("Login: ", this.sessionProvier.profile.value);

    if (this.sessionProvier.profile.value) {
      this.loginService.dispatch();
    }
  }

  ngOnDestroy(): void {
    this.showCloseSessionSubs.unsubscribe();
    this.invalidUserSubs.unsubscribe();
    this.profileSubs.unsubscribe();
  }

  //syrhax/logo-completo-215x249-2.png
  initCompany() {
    let company = this.cookieService.get("e");

    this.logoEmpresa = "habitat/logo-habitat.png";
    this.bgLoginImage = "syrsax/Logo_300x300px.png";
    this.bgBodyImage = "syrhax/back-1.jpeg";
    this.logoEmpresaClass = "habitat";

    /* switch (company) {
      case "3":
        this.logoEmpresa = "habitat/logo-habitat.png";
        this.bgLoginImage = "syrsax/Logo_300x300px.png";//"syrhax/logo-completo-215x249-2.png";
        this.bgBodyImage = "syrhax/back-1.jpeg";
        this.logoEmpresaClass = "habitat";
        break;
      case "1":
        this.logoEmpresa = "axkan/logo_solo_247x180.png";
        this.bgLoginImage = "syrsax/Logo_300x300px.png";
        this.bgBodyImage = "syrsax/bg-laptop.jpg";
        this.logoEmpresaClass = "axkan";
        break;
      case "2":
      default:
        this.logoEmpresa = "sbs/logo-sbsynergy.png";
        this.bgLoginImage = "syrsax/logo-completo-215x249.png"
        this.bgBodyImage = "syrsax/bg-laptop.jpg"
        this.logoEmpresaClass = "sbs";
        break;

    } */
  }

  login(event) {
    this.sessionProvier.loadingContent.next(true);
    this.loadLogin = true;
    this.auser.dispositivo = this.device.type;
    let company = this.cookieService.get("e");
    this.auser.company = company;

    this.loginService.authenticate(this.auser);

  }


  closeSessions(event) {
    let dwidth = '30%';
    if (this.isMobile()) {
      dwidth = '80%';
    } else if (this.isTablet()) {
      dwidth = '50%'
    } else {
      dwidth = '30%';
    }
    const ref = this.dialogService.open(CloseSessionComponent, {
      header: '¿Cerrar sesión abierta?',
      width: dwidth,
      data: { user: this.auser.user }
    });
  }

  changePass(event) {
    let dwidth = '30%';
    if (this.isMobile()) {
      dwidth = '80%';
    } else if (this.isTablet()) {
      dwidth = '50%';
    } else {
      dwidth = '30%';
    }
    const ref = this.dialogService.open(ForgotPasswordComponent, {
      header: 'Cambio de contraseña.',
      width: dwidth,
      data: { user: this.auser.user }
    });
  }



}
