import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DeviceView } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';
import { Menubar } from 'primeng/menubar';
import { Subscription } from 'rxjs';
import { Menu } from 'src/app/api/profile';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';

@Component({
  selector: 'axks-menu-bar',
  templateUrl: './menu-bar.component.html',
  styles: [`:host{
    ::ng-deep{
        .p-menubar.p-component{
            background-color: #E7E5E7;
            border: unset;
        }
        div.p-menubar.p-component.mobile {
          background-color: transparent;
          /* width: 40px !important; */
        }
    }
} `]
})
export class MenuBarComponent extends DeviceView implements OnInit, AfterViewInit, OnDestroy {

  /** Recibe menu items in Menu (@axks/components) array form. */
  @Input() menuItems: Menu[];

  /** MenuItem array for passing to p-menubar (primeng) component  */
  items: MenuItem[];

  /** 
   * Event to notify when a menu is selected.
   */
  @Output() onSelMenu = new EventEmitter<string>();

  /**
   * Primeng menubar component
   */
  @ViewChild(Menubar) menuBar: Menubar;

  screenSize = { width: 0, height: 0 };
  buttonWidth: number = 0;

  profileSubs: Subscription;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionPortalProv,
    private elem: ElementRef) {
    super(deviceService, messageService);
    this.profileSubs = sessionProvier.profile.subscribe({
      next: (profile) => {
        this.initMenu();
      }
    })
  }


  ngOnInit(): void {
    this.getScreenSize();
    this.initMenu();
  }

  ngOnDestroy(): void {
    this.profileSubs.unsubscribe();
  }

  initMenu() {

    if (!this.menuItems) {
      let profile = this.sessionProvier.profile.value;

      let emp = profile.empresas.find(e => e.idEmpresa == this.sessionProvier.empresa.value);
      let app = emp.apps.find(ap => ap.idAplicacion = this.sessionProvier.aplicacion.value);
      this.menuItems = app.menus;
    }

    this.items = undefined;
    if (this.menuItems) {
      this.items = this.transforMenus(this.menuItems);
    }

  }

  ngAfterViewInit(): void {
  }

  transforMenus(menuItems: Menu[]): MenuItem[] {

    let items = [] as MenuItem[];
    /* if (!menuItems) {
      //console.debug("No se proporcionan menus.")
      return items;
    } */
    if (menuItems) {
      menuItems.sort((a, b) => (a.posicion > b.posicion) ? 1 : -1);
    }

    for (let menu of menuItems) {

      let item = {} as MenuItem;
      item.styleClass = "ptl-menu-item";
      item.id = menu.idMenu;
      item.label = menu.menu;
      item.url = undefined;

      //if (menu.accion != undefined && menu.accion != '')
      if (menu.accion !== "") {
        if (menu.esUrl) {
          item.command = (event) => {
            this.selectMenu(menu.accion);
          };
        }
      }

      if (menu.menus && menu.menus.length > 0) {
        let submenu = this.transforMenus(menu.menus);
        item.items = submenu;
      }
      items.push(item);
    }


    return items;
  }



  selectMenu(item: string) {

    if (item) {
      this.sendCloseIframes();
      this.onSelMenu.emit(item);


    }
  }

  sendCloseIframes() {
    //console.log("Sending notification")
    let iframes = document.getElementsByTagName("iframe");
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        //console.log(iframes[i])
        iframes[i].contentWindow.postMessage("endsession", "*");
      }
    }
  }



  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    /* this.screenSize.height = window.innerHeight;
    this.screenSize.width = window.innerWidth; */

  }



  /** Para ocultarlo cuando se selecciona en el iframe */
  @HostListener('window:message', ['$event'])
  handleChildClick(event) {
    if (event.data.for == 'childClick') {
      this.menuBar.rootmenu.activeItem = null;
      this.menuBar.rootmenu.unbindDocumentClickListener();
      this.menuBar.cd.markForCheck();
    }
  }


}
