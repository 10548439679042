import { Component, OnInit } from '@angular/core';
import { DeviceView } from '@axks/components';
import { faAt, faBell, faBuilding, faCogs, faEnvelope, faUser, faUserCog, faUserTag, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Perfil } from 'src/app/api/profile';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';

@Component({
  selector: 'axks-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends DeviceView implements OnInit {
  faBell = faBell;
  faEnvelop = faEnvelope;
  faUser = faUser;
  faAt = faAt;
  faUserCog = faUserCog;
  faCogs = faCogs;
  faBuilding = faBuilding;
  faWindowRestore = faWindowRestore;
  faUserTag = faUserTag;

  prof: Perfil;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService:MessageService,
    protected sessionProvier: SessionPortalProv
    ) {
      super(deviceService,messageService);
     }

  ngOnInit(): void {

    this.initProfile();
  }

  initProfile(): Perfil{
    this.prof = this.sessionProvier.profile.value;


    //let usuario = this.getProfile() as  PerfilUsuario;
    let usuario = this.sessionProvier.profile.value;

    /* this.prof.user = usuario.usuario;
    this.prof.name = usuario.empleado.personales.nombre + " " + usuario.empleado.personales.apellidoPaterno;
    this.prof.position = usuario.empleado.puesto.puesto;
    this.prof.gender = usuario.empleado.personales.genero;
    this.prof.area = usuario.empleado.puesto.area.area;
    this.prof.mail = usuario.correo;

    this.prof.companies = [];
    usuario.empresas.forEach(
      (data) => {
        let company = {name: data.nombre, apps:[]} as ProfileCompany;
        this.prof.companies.push(company);
        data.apps.forEach(
          (app) => {
            if(app.esSistema){
              let papp = { name: app.nombre, rols:[]} as ProfileApp;
              company.apps.push(papp);
              app.roles.forEach((rol)=>{
                papp.rols.push(rol['nombre']);
              })

            }
          }
        ) 
      }
    ) */

    return this.prof;
  }


}
