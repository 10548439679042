import { Component, OnInit } from '@angular/core';
import { Message } from '@axks/components';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RebootPassPsService } from 'src/app/procs/reboot-pass-ps.service';

@Component({
  selector: 'axks-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [
  ]
})
export class ForgotPasswordComponent implements OnInit {


  user?: string;
  constructor(public config: DynamicDialogConfig,
    protected messageService: MessageService,
    public ref: DynamicDialogRef,
    private reboot: RebootPassPsService) {

  }

  ngOnInit(): void {
    this.user = this.config.data['user'];
  }

  rebootPass(event) {

    this.reboot.changePass(this.user).subscribe({
      next: (data) => {
        let mess = data as Message;

        this.ref.close();
        this.messageService.add({ severity: 'success', summary: mess.message, detail: mess.details })
        console.log(mess);
      },
      error: (error) => {
        this.messageService.add({ severity: 'error ', summary: 'Ocurrio un error', detail: 'Ocurrió un error al intentar cambiar la contraseña, favor de hablar con el administrador.' })
        console.log(error);
      }
    }


    );

  }


}