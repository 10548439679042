import { Injectable, OnDestroy } from '@angular/core';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Perfil } from '../api/profile';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Credentials } from '../api/login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionPortalProv implements OnDestroy {

  /**
   * Subject con el perfíl del usuario.
   */
  profile: BehaviorSubject<Perfil> = new BehaviorSubject<Perfil>(undefined);

  /**
   * Subject con el perfil del usuario simplificado destinado a las appicaciones cliente
   */
  singleProfile: BehaviorSubject<ProfileC> = new BehaviorSubject<ProfileC>(undefined);

  /** Subject con el token */
  token: BehaviorSubject<Credentials> = new BehaviorSubject<Credentials>(undefined);

  /**
   * Subject con la empresa seleccionada.
   */
  empresa: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  /**
   * Subject con la aplicación seleccionada
   */
  aplicacion: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  /** Subject que indica si actualmente se está cargando el contenido. */
  loadingContent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Subject que notifica la solicitud de cierre de sesión. */
  closeSession: Subject<void> = new Subject<void>();

  /** Subject que notifica si se está cargando el login */
  loadLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);




  profileSubs: Subscription;
  sprofileSubs: Subscription;
  tokenSubs: Subscription;
  empresaSubs: Subscription;
  appSubs: Subscription;

  constructor() {
    this.profileSubs = this.profile.subscribe({
      next: (data) => {
        if (data != undefined)
          sessionStorage.setItem("profile", window.btoa(JSON.stringify(data)));
      }
    });

    this.sprofileSubs = this.singleProfile.subscribe({
      next: (data) => {
        if (data != undefined)
          sessionStorage.setItem("sprofile", window.btoa(JSON.stringify(data)));
      }
    });

    this.tokenSubs = this.token.subscribe({
      next: (data) => {
        if (data != undefined)
          sessionStorage.setItem("token", window.btoa(JSON.stringify(data)));
      }
    });

    this.empresaSubs = this.empresa.subscribe({
      next: (data) => {
        if (data != undefined) {
          sessionStorage.setItem("empresa", data.toString());
          this.actualizaEmpresa();
        }

      }
    });

    this.appSubs = this.aplicacion.subscribe({
      next: (data) => {
        if (data != undefined)
          sessionStorage.setItem("app", data.toString());
      }
    });

  }
  ngOnDestroy(): void {

    this.profileSubs.unsubscribe();
    this.sprofileSubs.unsubscribe();
    this.tokenSubs.unsubscribe();
    this.empresaSubs.unsubscribe();
    this.appSubs.unsubscribe();
  }

  invalidate() {
    if(environment.dev == true)
      console.log("Invalidate");

    this.token.next(undefined);
    this.profile.next(undefined);
    this.singleProfile.next(undefined);
    sessionStorage.removeItem("cred");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem("sprofile");
    sessionStorage.removeItem("token")

  }

  recoverySession() {
    let prof = sessionStorage.getItem("profile");
    if (prof) {
      let jprof = JSON.parse(window.atob(prof));
      this.profile.next(jprof);
    }

    let sprof = sessionStorage.getItem("sprofile");
    if (sprof) {
      let jsprof = JSON.parse(window.atob(sprof));
      this.singleProfile.next(jsprof);
    }

    let token = sessionStorage.getItem("token");
    if (token) {
      let jtoken = JSON.parse(window.atob(token));
      this.token.next(jtoken);
    }
  }

  hasRol(rol: number | string): boolean {

    let prof = this.profile.value;


    if (prof) {
      let emp = prof.empresas.find(e => e.idEmpresa === this.empresa.value);
      let app = emp.apps.find(a => a.idAplicacion === this.aplicacion.value);

      let exists = app.roles.find((profRol) => (profRol.idRol === rol || profRol.rol === rol));

      return (!exists) ? false : true;

    }

    return false;
  }

  private actualizaEmpresa() {
    if (this.singleProfile.value) {
      let sprof = this.singleProfile.value;

      sprof.empresa = this.empresa.value;
      this.singleProfile.next(sprof);
    }
  }

}
