<div class="grid">

    <div [class]="(isMobile() ? 'col-2 ' :'col-3 ' ) + 'sm:col-1 md:col-1 lg:col-3 xl:col-3' ">
        <div style="display: flex;" (click)="logoClick($event)">
            <a title="Detonador">
                <!-- <img *ngIf="idEmpresa != 3" src="assets/images/{{logoMinSistema}}" height="53px"
                    style="cursor: pointer; padding-left: 5px;"> -->
                <img  src="assets/images/{{logoMinSistema}}" height="53px"
                    style="cursor: pointer; padding-left: 5px;">
            </a>

            <a *ngIf="isDesktop()">
                <img src="assets/images/{{logoSistema}}" height="50px" style="cursor: pointer;">
            </a>
            <span *ngIf="isDesktop()" class="lbl-version">{{version}}</span>&nbsp;

        </div>

    </div>

    <div [class]="(isMobile() ? 'col-8 ' :'col-7 ' ) + 'sm:col-2 md:col-7 lg:col-7 xl:col-7 '">
        <ng-content></ng-content>
    </div>

    <div class="col-2 sm:col-7 md:col-2 lg:col-2 xl:col-2" *ngIf="loadContent">
        <axks-system-actions></axks-system-actions>
    </div>

</div>