<br>
<div class="grid" style="width: 100%;padding-left: 20px;">
    <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-field-row" >
        <span class="p-float-label" >
            <input id="float-input" type="text" pInputText [(ngModel)]="user"> 
            <label for="float-input">Username</label>
        </span>
    </div>
    <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-button-row">
        <p-button label="Cerrar sesión" (click)="cerrar($event)" ></p-button>
    </div>
</div>
