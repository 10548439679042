<p-card class="prof-card" >
    <div class="grid">
        <div class="col-12">
            <div class="prof-name" style="font-size: 1.2em;">
                <div class="prof-avatar">
                    <img src="assets/images/avatar/avatar.jpeg" *ngIf="prof.genero=='M'">
                <img src="assets/images/avatar/avatar-fem.jpeg" *ngIf="prof.genero=='F'">
                </div>
                {{prof.nombre}} {{prof.apPaterno}}
            </div>
        </div>
        <div class="col-6">
            <div class="prof-rol"> 
                <fa-icon [icon]="faUser" style="font-size: 25px;"></fa-icon>{{prof.usuario}}
            </div>
            <div class="prof-rol"> 
                <fa-icon [icon]="faAt" style="font-size: 25px;"></fa-icon>{{prof.correo}} 
            </div>
            <div class="prof-rol"> 
                <fa-icon [icon]="faCogs" style="font-size: 25px;"></fa-icon>{{prof.area}} 
            </div>
            <div class="prof-rol"> 
                <fa-icon [icon]="faUserCog" style="font-size: 25px;"></fa-icon>{{prof.puesto}} 
            </div>
            <!-- <div class="prof-rol" > {{prof.sysRol}} </div>  -->
        </div>

        <div class="col-6">
            <div class="prof-rol" *ngFor="let empresa of prof.empresas"> 
                <fa-icon [icon]="faBuilding" style="font-size: 25px;"></fa-icon>{{empresa.empresa}}
                <div class="prof-rol" *ngFor="let app of empresa.apps">
                    <fa-icon [icon]="faWindowRestore" style="font-size: 25px;"></fa-icon>{{app.aplicacion}}
                    <div class="prof-rol" *ngFor="let rol of app.roles">
                        <fa-icon [icon]="faUserTag" style="font-size: 25px;"></fa-icon>{{rol.rol}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>