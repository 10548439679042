<!-- <p-progressSpinner *ngIf="loadLogin" [style]="{width: '50px', height: '50px'}" styleClass="axks-progress-spinner"
    strokeWidth="8"></p-progressSpinner> -->


<div class="bg-auth" style="background-image: url('assets/images/{{bgBodyImage}}');"></div>
<p-card class="auth-card">

    <div class="grid">

        <div class="col-4" style="text-align: center;">
            <img src="assets/images/{{bgLoginImage}}" style="opacity: .4; width: 250px; height: 260px;">
        </div>
        <div class="col-8" style="background-color: rgba(220,220,220,.3);padding-top: 20px;">
            <div class="grid">
                <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-field-row">
                    <span class="p-float-label">
                        <input id="float-input" type="text" pInputText [(ngModel)]="auser.user"
                            (keydown.enter)="login($event)">
                        <label for="float-input">Usuario</label>
                    </span>
                </div>
                <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-field-row">
                    <span class="p-float-label">
                        <input id="fl-pass" type="password" pPassword [feedback]="false" [(ngModel)]="auser.pass"
                            (keydown.enter)="login($event)" />
                        <label for="fl-pass">Contraseña</label>
                    </span>
                </div>
                <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-button-row">
                    <p-button label="Entrar" (click)="login($event)" [disabled]="loadLogin"></p-button>
                </div>
                <div class="sm:col-12 md:col-12 lg:col-8 xl:col-12 col-12" style="text-align: right">
                    <button pButton type="button" label="¿Olvidaste tu contraseña?" class="p-button-link"
                        (click)="changePass($event)"></button>
                </div>
                <div class="sm:col-12 md:col-12 lg:col-12 xl:col-12 col-12 auth-link-row">
                    <button pButton type="button" label="Cerrar sesión." *ngIf="showCloseSession" class="p-button-link"
                        (click)="closeSessions($event)"></button>
                    <!-- <button pButton type="button" label="Olvide mi contraseña." 
                    class="p-button-link" (click)="lostPass($event)"></button> -->

                </div>
            </div>

        </div>
    </div>
    <img src="assets/images/{{logoEmpresa}}" width="80px"
        class="auth-company-logo auth-company-logo-{{logoEmpresaClass}}">
</p-card>

<p-toast></p-toast>