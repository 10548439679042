import { Component } from '@angular/core';

@Component({
  selector: 'axks-blank',
  template: `
    
  `,
  styles: [
  ]
})
export class BlankComponent {

}
