<p-toolbar>
    <div class="p-toolbar-group-left"></div>
    <div class="p-toolbar-group-right" style="float: right  !important;">
        <ng-container *ngIf="companyMenu.length > 0">
            <p-avatar icon="c-icons {{companyClass}}" shape="circle" (click)="companymenu.toggle($event)"></p-avatar>
            <p-tieredMenu [model]="companyMenu" [popup]="true" #companymenu></p-tieredMenu>
        </ng-container>
       
        <p-avatar icon="pi pi-bell" pBadge [value]="notifsNumber" severity="danger" shape="circle"
            (click)="notificaMenu.toggle($event)"></p-avatar>
        <p-slideMenu [model]="notifMenu" [popup]="true" #notificaMenu [style]="{ width: '400px' }" [menuWidth]="400"
            [viewportHeight]="300"></p-slideMenu>
        <p-avatar image="assets/images/avatar/avatar-fem.jpeg" *ngIf="gender=='F'" shape="circle"
            (click)="avatarmenu.toggle($event)"></p-avatar>
        <p-avatar image="assets/images/avatar/avatar.jpeg" *ngIf="gender=='M'" shape="circle"
            (click)="avatarmenu.toggle($event)"></p-avatar>
        <p-tieredMenu [model]="avatarMenu" [popup]="true" #avatarmenu></p-tieredMenu>

        
    </div>
</p-toolbar>

<div class="sys-act-bar">
    <!--BOTON DE REGISTRAR NOTIFICACIONES-->
    <!-- <axks-form-registrar-notificaciones></axks-form-registrar-notificaciones> -->

    <p-dialog header="Perfíl de Usuario" [(visible)]="showProfileDetail" [maximizable]="true" [modal]="true"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '80vw'}" [draggable]="false"
        [resizable]="false" position="top">
        <axks-profile></axks-profile>
    </p-dialog>

    <p-dialog header="Notificaciones" [(visible)]="showNotif" [maximizable]="true" [modal]="true"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}" styleClass="axks-notif-dialog" [draggable]="false"
        [resizable]="false" position="top">
        <iframe #notifif id="notifif" [src]="path | safeUrl"></iframe>
    </p-dialog>

    <!--Modal de notificacion-->
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [rejectVisible]="false" [acceptVisible]="false">
    </p-confirmDialog>

</div>