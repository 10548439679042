import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, AfterViewInit, isDevMode } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DeviceView } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';
import { Subscription } from 'rxjs';
import { Empresa } from 'src/app/api/empleado';
import { Aplicacion, Perfil, Menu, Rol } from 'src/app/api/profile';
import { LoginService } from 'src/app/procs/login.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent extends DeviceView implements OnInit, OnDestroy, AfterViewInit {

  selCompany: Empresa;
  selApp: Aplicacion;
  selRol: Rol;
  profile: Perfil;

  menuItems: Menu[];

  showDashboard: boolean = true;

  @ViewChild("iws") iwsFrame: ElementRef;
  lastUrlWs: string;

  showProgress: boolean = false;

  iframeUrl: SafeUrl;//SafeResourceUrl;


  loadContent: boolean = false;

  profSubs: Subscription;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private sessionProv: SessionPortalProv,
    private loginSv: LoginService,
    protected router: Router,
    private sanitizer: DomSanitizer) {
    super(deviceService, messageService);

    this.profSubs = sessionProv.profile.subscribe({
      next: (prof) => {
        if (prof && prof.usuario) {
          this.profile = prof;
          this.initPortal();
          this.loadContent = true;
        }
      }
    })
  }

  ngOnInit(): void {
    if (isDevMode())
      console.log("Profile: ", this.sessionProv.profile.value);
    if (this.sessionProv.profile.value) {
      this.loadContent = true;
    }
    setTimeout(() => {
      if (!this.sessionProv.profile.value) {
        this.loginSv.redirectToLogin();
      }
    }, 2000);
    //this.sessionProv.loadingContent.next(false);

  }

  ngAfterViewInit(): void {
    this.sessionProv.loadingContent.next(false);
  }

  ngOnDestroy(): void {
    this.profSubs.unsubscribe();
  }


  initPortal() {
    //this.initTheme();
    this.loadMenuProfile();
  }

  loadMenuProfile() {

    let profile = this.sessionProv.profile.value;

    let emp = profile.empresas.find(e => e.idEmpresa == this.sessionProv.empresa.value);
    let app = emp.apps.find(ap => ap.idAplicacion = this.sessionProv.aplicacion.value);
    this.menuItems = app.menus;


  }

  selMenuHandler(event) {


    let idEmpresa = this.sessionProv.empresa.value;
    let selAppId = this.sessionProv.aplicacion.value;

    //if (this.lastUrlWs != event) {
    //this.showProgress = true;
    this.sessionProv.loadingContent.next(true);

    if (this.iwsFrame) {
      this.iwsFrame.nativeElement.src = "/blank";
    }

    this.lastUrlWs = event;

    this.showDashboard = false;


    setTimeout(() => {
      if (event != null || event !== undefined) {
        let scred = sessionStorage.getItem("cred");


        // no hay un acceso válido
        if (!scred) {
          this.router.navigate(['login']);
          return;
        }


        let b = window.btoa(scred);
        event += !event.includes("?") ? "?" : "&";
        // >>>>>> ELIMINAR ANTES DE DESPLEGAR
        /* if (event.includes("rh.dev-gui.axkans.org")) {
          let p = event as string;
          event = p.replace("rh.dev-gui.axkans.org","localhost:4200");
        }else{
          event = "http://localhost:4200/?"
        } */
        //event = "http://10.30.3.68:4200/?"
        // >>>>>> FIN DE LO QUE HAY QUE ELIMINAR


        let path = event + "e=" + idEmpresa + "&a=" + selAppId + "&s=" + b;

        //console.log("Path: ")
        //console.log(path)
        //this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path);
        //console.log(this.iframeUrl)
        //this.iframeUrl = event + "?e=" + idEmpresa + "&s=" + scred;
        this.iwsFrame.nativeElement.src = path;

      }
    }, 10);
    //}
    setTimeout(() => {
      if (this.sessionProv.loadingContent.value == true)
        this.sessionProv.loadingContent.next(false);
    }, 20000);

  }






  handleDashboard(event) {
    if (event == true) {
      if (this.iwsFrame) {
        this.iwsFrame.nativeElement.src = "/blank";
        //this.sendCloseIframes();
      }
      this.showDashboard = true;
    }
  }

  iframeLoad(event) {

    //setTimeout(() => {
    this.showProgress = false;
    //}, 3500);
  }

  @HostListener('window:message', ['$event'])
  childMessages(event) {
    if (event.data.for == "childControl") {
      if (isDevMode())
        console.log("Child message:", event.data);

      this.showProgress = false;
      this.sessionProv.loadingContent.next(false);
    }

    if (event.data.for == "authenticate") {
      if (isDevMode())
        console.log("Authenticate message: ", event.data);
      if (this.showDashboard) {
        let iframes = document.getElementsByTagName("iframe");
        for (let i = 0; i < iframes.length; i++) {
          iframes[i].contentWindow.postMessage({ for: 'childProfile', appId: 'dashboard', profile: this.sessionProv.singleProfile.value }, "*");
        }
      } else {
        if (isDevMode())
          console.log("Send profile: ", event.data.appId, this.sessionProv.singleProfile.value);

        this.iwsFrame.nativeElement.contentWindow.postMessage({ for: 'childProfile', appId: event.data.appId, profile: this.sessionProv.singleProfile.value }, "*");
      }
    }

    // Captura solicitud de redirección...
    if (event.data.for == "redirect") {

      let url = event.data.url;
      if (event.data.params) {
        let params = event.data.params;
        let pkeys = Object.keys(params);

        if (pkeys && pkeys.length > 0) {
          url += "?";
          pkeys.forEach((p) => {
            url += p + "=" + params[p] + "&";
          });
          url = url.substring(0, url.length - 1);
        }

      }
      this.selMenuHandler(url);
    }

  }

}
